import React, { Component } from 'react'
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';

export default class OnlineAdmission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sname: '',
            gender: '',
            aadhar:'',
            dob:'',
            religion:'',
            caste:'',
            fname:'',
            mname:'',
            gname:'',
            pvill:'',
            ppo:'',
            pps:'',
            pdist:'',
            pstate:'',
            ppin:'',
            rvill:'',
            rps:'',
            rpo:'',
            rdist:'',
            rstate:'',
            rpin:'',
            contact:'',
            email:'',
            bank:'',
            branch:'',
            accno:'',
            ifsc:'',
            pschool:'',
            pschooladdr:'',
            pschoolboard:'',
            psession:'',
            examroll:'',
            examno:'',
            bngmarks:'0',
            engmarks:'0',
            mathmarks:'0',
            phymarks:'0',
            lifmarks:'0',
            histmarks:'0',
            geomarks:'0',
            arbmarks:'0',
            islmarks:'0',
            combi:'',
        }
        this.onChange = this.onChange.bind(this)
    }
    onChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
        console.log(this.state);
    }
    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={10}>
                        <Form>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                Student Name
                                </Form.Label>
                                <Col sm={8}>
                                <Form.Control type="text" name="sname" placeholder="Full Name" value={this.state.sname} onChange={this.onChange} />
                                </Col>
                            </Form.Group>
                            <fieldset>
                                <Form.Group as={Row}>
                                <Form.Label as="legend" column sm={2}>
                                    Gender
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control as="select" custom name="gender" value={this.state.gender}  onChange={this.onChange} >
                                            <option selected>-Select-</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                    </Form.Control>
                                </Col>
                                </Form.Group>
                            </fieldset>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                Aadhaar Card No
                                </Form.Label>
                                <Col sm={8}>
                                <Form.Control type="number" name="aadhar" placeholder="Aadhaar No." value={this.state.aadhar} onChange={this.onChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm={2}>
                                Date Of Birth
                                </Form.Label>
                                <Col sm={8}>
                                <Form.Control type="date" name="dob" placeholder="Date of birth"  value={this.state.dob} onChange={this.onChange}/>
                                </Col>
                            </Form.Group>
                                <Form.Group as={Row} controlId="formGridState">
                                    <Form.Label column sm={2}>
                                    Religion
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control as="select" custom name="religion" value={this.state.religion}  onChange={this.onChange} >
                                         <option value="">- Select -</option>
										<option value="HINDUISM">HINDUISM</option>
										<option value="ISLAM">ISLAM</option>
										<option value="CHISTIAN">CHISTIAN</option>
										<option value="BUDHIST">BUDHIST</option>
										<option value="SIKH">SIKH</option>
										<option value="OTHER">OTHER</option>
                                    </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formGridState">
                                    <Form.Label column sm={2}>
                                    Category / Caste
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" custom name="caste" value={this.state.caste}  onChange={this.onChange} >
                                            <option value="">- Select -</option>
                                            <option value="General">General</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                            <option value="OBC-A">OBC-A</option>
                                            <option value="OBC-B">OBC-B</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <div style={{backgroundColor:'#ddd'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Father's Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="fname" placeholder="Father Name"  value={this.state.fname}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Mother's Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="mname" placeholder="Mother Name" value={this.state.mname}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Guardian's Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="gname" placeholder="Guardian Name" value={this.state.gname}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>
                                </div>
                                <div style={{backgroundColor:'#f5f5f5'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Vill
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="pvill" placeholder="Vill" value={this.state.pvill}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    PO
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="ppo" placeholder="Post Office"  value={this.state.ppo}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    PS
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="pps" placeholder="Police Station" value={this.state.pps}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Dist
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="pdist" placeholder="District"  value={this.state.pdist}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formGridState">
                                    <Form.Label column sm={2}>
                                    State
                                    </Form.Label>
                                     <Col sm={8}>
                                        <Form.Control as="select" custom name="pstate" value={this.state.pstate}  onChange={this.onChange} >
                                            <option value="">- Select -</option>
                                            <option value="General">General</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                            <option value="OBC-A">OBC-A</option>
                                            <option value="OBC-B">OBC-B</option>
                                         </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Pin
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="number" name="ppin" placeholder="Pin Code" value={this.state.ppin}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                </div>
                                <div style={{backgroundColor:'#f5f5f5'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Vill
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="rvil" placeholder="Vill" value={this.state.rvill}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    PO
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="rpo" placeholder="Post Office" value={this.state.rpo}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    PS
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="rps" placeholder="Police Station" value={this.state.rps}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Dist
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="rdist" placeholder="Districe" value={this.state.rdist}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formGridState">
                                    <Form.Label column sm={2}>
                                    State
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" custom name="rstate" value={this.state.rstate}  onChange={this.onChange} >
                                            <option value="">- Select -</option>
                                            <option value="General">General</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                            <option value="OBC-A">OBC-A</option>
                                            <option value="OBC-B">OBC-B</option>
                                         </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Pin
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="number" name="rpin" placeholder="Pin Code" value={this.state.rpin}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                </div>
                                <div style={{backgroundColor:'#f5f5f5'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Contact No
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="contact" placeholder="Contact No." value={this.state.contact}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail" >
                                    <Form.Label column sm={2}>
                                    Email
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="email" placeholder="Email Id" value={this.state.email}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>
                                 
                                </div>
                                <div style={{backgroundColor:'#f5f5f5'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Bank Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="bank" placeholder="Bank Name" value={this.state.bank}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Branch Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="branch" placeholder="Branch Name"  value={this.state.branch}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Account No.
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="accno" placeholder="Account No." value={this.state.accno}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    IFSC Code.
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="ifsc" placeholder="IFSC Code" value={this.state.ifsc}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>
                                </div>
                                <div style={{backgroundColor:'#f5f5f5'}}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Previous School Name
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="Text" name="pschool" placeholder="Previous School Name" value={this.state.pschool}  onChange={this.onChange} />
                                    </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Previous School Address
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="pschooladdr" placeholder="Previous School Address" value={this.state.pschooladdr}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Previous School Board
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control type="email" name="pschoolboard" placeholder="Previous School Board"  value={this.state.pschoolboard}  onChange={this.onChange}/>
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Year/Session passed
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control as="select" custom name="psession" value={this.state.psession}  onChange={this.onChange} >
                                            <option value="">- Select -</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                         </Form.Control>
                                    </Col>
                                    </Form.Group>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Board Exam's Roll</Form.Label>
                                            <Form.Control type="text" placeholder="Exam's Roll" name="examroll" value={this.state.examroll}  onChange={this.onChange}/>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Board Exam's No</Form.Label>
                                            <Form.Control type="number" placeholder="Exam's No" name="examno" value={this.state.examno}  onChange={this.onChange} />
                                            </Form.Group>
                                        </Row>
                                 
                                </div>
                                <div>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Bengali</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="bngmarks"  value={this.state.bngmarks}  onChange={this.onChange}/>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>English</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="engmarks"  value={this.state.engmarks}  onChange={this.onChange}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Mathematics</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="mathmarks"   value={this.state.mathmarks}  onChange={this.onChange}/>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Physical Science</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="phymarks"  value={this.state.phymarks}  onChange={this.onChange}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Life Science</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="lifmarks" value={this.state.lifmarks}  onChange={this.onChange} />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>History</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="histmarks" value={this.state.histmarks}  onChange={this.onChange}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Geography</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="geomarks" value={this.state.examno}  onChange={this.onChange}/>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Arabic</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="arbmarks"  value={this.state.examno}  onChange={this.onChange}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Islam Porichoi</Form.Label>
                                            <Form.Control type="number" placeholder="0" name="islmarks" value={this.state.examno}  onChange={this.onChange}/>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                            </Form.Group>
                                           
                                        </Row>
                                        
                                    <Form.Group as={Row} controlId="formGridState">
                                    <Form.Label column sm={2}>
                                    Subject Combination
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Control as="select" custom name="combi" value={this.state.combi}  onChange={this.onChange} >
                                         <option value="">- Select -</option>
										<option value="HINDUISM">HINDUISM</option>
										<option value="ISLAM">ISLAM</option>
										<option value="CHISTIAN">CHISTIAN</option>
										<option value="BUDHIST">BUDHIST</option>
										<option value="SIKH">SIKH</option>
										<option value="OTHER">OTHER</option>
                                    </Form.Control>
                                    </Col>
                                </Form.Group>
                                </div>
                                <div>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Choose Photo</Form.Label>
                                    <Form.Control type="file" />
                                </Form.Group>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Choose Signature</Form.Label>
                                    <Form.Control type="file" multiple />
                                </Form.Group>
                                </div>
                            
                            
                            

                            <Form.Group as={Row}>
                                <Col sm={{ span: 10, offset: 2 }}>
                                <Button type="submit">Submit From</Button>
                                </Col>
                            </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
