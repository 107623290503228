import logo from './logo.svg';
import './App.css';
import Header from './components/Header'
import Home from './components/Home';
import Footer from './components/Footer';
import OnlineAdmission from './components/OnlineAdmission';
import OnlineAdmissionNotice from './components/OnlineAdmissionNotice';
import {Route, Switch, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/OnlineAdmission" component={OnlineAdmission} />
          <Route exact path="/OnlineAdmissionNotice" component={OnlineAdmissionNotice} /> */}
          {/* <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/Registration" component={Registration} />
          <Route exact path="/PrintForm" component={PrintForm} />
          <Route exact path="/Courses" component={Courses} /> */}
        </Switch>
        </BrowserRouter>
      <Footer />
      </>
  );
}

export default App;
