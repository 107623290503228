import React, { Component } from 'react'
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';

export default class OnlineAdmissionNotice extends Component {
    render() {
        return (
            <>
            <div>
                <Container>
                    <Row>
                        <Col md={10}>
                            <div>
                            <center> <h1>KOHM</h1></center>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{marginTop:'50px',  backgroundColor:'#f5f5f5', height:'600px'}}>
            <Container>
                <Row>
                    <Col md={10}>
                       
                         <div style={{marginTop:'50px', padding:'20px', backgroundColor:'#f5f5f5'}}>
                           <center>  <h2>অতি গুরুত্বপূর্ণ তথ্য মনোযোগ সহকারে এ পড়ুন</h2></center>
                         </div>  
                         <div>
                            <ol style={{lineHeight:"30px"}}>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                                    <li>বাংলা রচনা সমগ্র এখানে পর্যায়ক্রমে তুলে ধরা হয়েছে। বলতে পারেন এই অ্যাপটি</li>
                            </ol>
                         </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div style={{marginTop:'50px',   height:'400px'}}>
            <Container>
                <Row>
                    <Col md={10}>
                        <a href="OnlineAdmission">Get Online Application Form</a>
                        
                    </Col>
                </Row>
            </Container>
        </div>
        </>
        )
    }
}
