import React, { Component } from 'react'
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';
import Carosel from './Carosel';
import StaffCarousel from './StaffCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faGlobeAmericas, faUserMd, faClipboardList, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import GalleryHome from './GalleryHome';

export default class Home extends Component {
    constructor(props) {

        super(props);

        this.state = {
            items: [],
            isLoaded: false
        }

    }
    componentDidMount() {
        localStorage.setItem('sID', null);
        fetch('https://baidarajkhighschool.org/api/getAllNotice.php')
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json,
                    isLoaded: true, 
                })
                console.log(json);
            }).catch((err) => {
                console.log("hjhj :",err);
            });

    }
    render() {
        return (
            <div>
                <Carosel />
               <section>
                   <Container fluid>
                       <Row>
                           <Col md={4}>
                                <div className="tophd" >
                                    <h2 className="title" >Quick&nbsp;<strong>Links</strong></h2>
                                </div>
                                </Col>
                                <Col mod={8}>
                                <div className="linklist">
                                    <div className="linkdiv">
                                         {/* <FontAwesomeIcon icon={faGlobeAmericas} className="big-icon"  size="3x" /> */}
                                         <img src="img/pp.png" width="80"></img>
                                          <div className="quickDiv"> <a href="onlineAdmission" target="_self" className="text">Online Admission</a>
                                          </div> 
                                    </div>
                                    
                                    <div className="linkdiv">
                                        {/* <FontAwesomeIcon icon={faClipboardList}  size="3x" color="#670C12" /> */}
                                         <img src="img/mdm.png" width="80"></img>
                                        <div className="quickDiv">
                                           <a href="#" target="_blank" className="text">MDM Distribution</a>
                                           </div>
                                    </div>
                                </div>
                                
                           </Col>
                       </Row>
                   </Container>
               </section>
               <section>
                   <Container>
                       <Row>
                           <Col >
                                <Card style={{marginTop:'40px'}} className="cardNotice">
                                <Card.Header className="text-center noticeHd">Notice</Card.Header>
                                    <div style={{padding:'20px',maxHeight: "760px"}} className="overflow-auto">
                                    {this.state.items.map(item => (
                                        <Card className="cardNotice" style={{marginBottom:'15px'}}>
                                            <Row>
                                                <Col md={2} style={{ }}>
                                                    <div style={{padding: "25px"}}>
                                                     <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                    </div>
                                                </Col>
                                                <Col md={10}>
                                                    <Card.Body style={{padding: '5px '}}>
                                                    <Card.Title>{item.notice_title}
                                                    {(item.blink)?
                                                    <svg height="100" width="100" class="blinking">
                                                        <circle cx="50" cy="50" r="10" fill="red" />
                                                        Sorry, your browser does not support inline SVG.  
                                                        </svg> 
                                                        :''}

                                                    </Card.Title>
                                                    <Card.Text>
                                                    {item.notice_body}
                                                    <text style={{paddingLeft: '20px'}}><a href={item.link}>Click Here .....</a></text>
                                                    </Card.Text>
                                                    </Card.Body>
                                                </Col>
                                            </Row>
                                        </Card>
                                        
                                       
                                    ))}
                                    </div>
                                </Card>
                           </Col>
                       </Row>
                   </Container>
               </section>
               <section>
                  
                                <Container className="text-center container-review">
                <div className="header-review text-muted"> <h4>OUR ASSETS </h4></div>
                <Row>
                <div className="card-review col-md-4 col-11 third-review">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/secretary.png" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faUser} className="quoteLeft" /> We are grateful to all the people of the area for choosing such a cold-headed secretary. After being appointed as the secretary, he only thought about the improvement of the School, when the School has become a priority zone, he left all his work and came to touch only for the progress of education.. <FontAwesomeIcon icon={faUser} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro ">MOSARAF HOSSAIN, MLA (Itahar)</small> </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="card-review col-md-4 col-11 second-review">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/tic.jpg" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faUser} className="quoteLeft" /> One of the best TIC in terms of work and manners. He always creates a good environment for boys and girls to read and listen so that no one has any problem. Very friendly attitude towards students and staffs. He keeps the staff room busy all the time and is a very dear person to the students, teachers and locals.. <FontAwesomeIcon icon={faUser} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro ">AZIZUR RAHAMAN (TIC), M.Sc, B.Ed</small> </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-review col-md-4 col-11">
                        <div className="card-content">
                            <div className="card-body p-0">
                                <div className="profile mb-4 mt-3"> <img src="img/ahm.jpeg" /> </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> <FontAwesomeIcon icon={faUser} className="quoteLeft" />  He took immediate necessary steps to find a solution to any problem of the staff and students and to make it easier.LIke a team of Staffing Consultants with expertise in solving of problems. He keeps an eye on whether the reading of the School is going on properly. He has won the hearts of everyone with his work skills. <FontAwesomeIcon icon={faUser} className="quoteLeft fa-flip-horizontal" /> </small> </p>
                                </div>
                                <div className="card-body company"> <i className="fab fa-ebay fa-2x"></i> <small className="intro ">MD MESHBAHUL ALAM (AHM), Asstt. Superintendent</small> </div>
                            </div>
                        </div>
                    </div>
                    
                </Row>
            </Container> 
                              
               </section>
               <section>
                   <GalleryHome />
               </section>
               <section>
                   <StaffCarousel />
               </section>
               <section  >
                    <div className="resultSection" style={{backgroundImage:"url('img/back.png')"}}>
                        <Container>
                            <Row>
                                <Col>
                                        <div>
                                            <center><div className="aboutHd">ABOUT OUR INSTITUTE</div></center>

                                        </div>
                                        <div style={{    textAlign: 'center',padding: '10px'}}>
                                        <p>extends a very warm welcome to the school-going students of Eastern India from Class VII to Class XII. Pathfinder, the leading institute in Eastern India guides students for competitive exams like JEE( main & Advanced), NEET-UG, WBJEE, Olympiads, KVPY and allied competitive exams. We also guide students for Class X and Class XII Board examinations for all Boards. If you are hard-working and sincere and aspire to achieve academic excellence, we shall be happy to nurture and guide you and make your dreams come true.</p>
                                        </div>
                                        <Container style={{marginTop:'50px',paddingBottom:'20px'}}>
                                            <Row>
                                                <Col md={3} className="aboutSection">
                                                    <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>30 YEARS</h3>
                                                            <h5>Experience</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="aboutSection">
                                                    <div className="text-center">
                                                            <div>
                                                                <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                            </div>
                                                            <div>
                                                                <h3>24 K</h3>
                                                                <h5>Happy Students</h5>
                                                            </div>
                                                        </div>
                                                </Col>
                                                <Col md={3} className="aboutSection">
                                                <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>08+</h3>
                                                            <h5>Course</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                <div className="text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon={faBullhorn}  size="3x" color="#f06f24" />
                                                        </div>
                                                        <div>
                                                            <h3>20+</h3>
                                                            <h5>Subjects</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                </Col>
                            </Row>
                        </Container>
                            
                    </div>
               </section>
               <section style={{backgroundColor:'#f5f5f5',paddingBottom: '50px'}}>
                   <Container style={{marginTop:'40px'}}>
                       <Row>
                           <Col md={12}>
                                <div style={{padding:"40px"}}>
                                    <div><center><h1>Our Courses</h1></center></div>
                                    <div><center><p style={{padding:"20px"}}>We offers focused concept based learnig approach with personalised feedback to aspiring to aspiring students.</p></center></div>
                                </div>
                                <Container>
                                    <Row>
                                    <Col md={4}>
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src="img/ict.png" />
                                            <Card.Body>
                                                <Card.Title>Our School Have running an ICT Project</Card.Title>
                                                <Card.Text>
                                                There have Learn First stage Computer all the students there have a computer Lab also (By Exrta Marks)

                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src="img/Madrasah.png" />
                                            <Card.Body>
                                                <Card.Title>Our School Serve Class V to Class XII</Card.Title>
                                                <Card.Text>
                                                The equivalent of Higher Secondary Afflated by West Bengal Board of Secondary and Higher Secondary Education
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img   variant="top" src="img/Vocational.png" />
                                            <Card.Body>
                                                <Card.Title>Vocational Traning Centre</Card.Title>
                                                <Card.Text>
                                                Our Institute provided Vocational Cources
Like Tailoring, Mobile Reapring, Computer Networking Maintainence and under W.B.S.C.T.V.E & S.D.

                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    </Row>
                                </Container>
                           </Col>
                       </Row>
                   </Container>
               </section>
               <section style={{padding: "35px",backgroundColor:'#fff', paddingBottom:'40px'}}>
               <Container fluid   style={{padding: "35px", height:'600px'}}>
                <Row>
                    <Col>
                    <div><h1>Find us on Map</h1></div>
                    <div style={{ height: '57vh', width: '100%' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.6107831212285!2d88.16254991545858!3d25.31727773302994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fadf8198b78b71%3A0x9c0d0d56c8804a8b!2sBaidara%20Janakalyan%20High%20School!5e0!3m2!1sen!2sin!4v1627378933796!5m2!1sen!2sin" width="100%" height="520" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                    </Col>
                </Row>
            </Container >
            </section>
            </div>
        )
    }
}
