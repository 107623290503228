import React, { Component } from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import { Card,Button, Navbar, Nav, NavDropdown, Form, FormControl ,Carousel , Container,Row,Col} from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
const images = [
    {
      original: 'img/Galary0.jpeg',
      thumbnail: 'img/Galaryth0.png',
    },
    {
      original: 'img/Galary1.jpeg',
      thumbnail: 'img/Galaryth1.png',
    },
    {
      original: 'img/Galary2.jpeg',
      thumbnail: 'img/Galaryth2.png',
    },
    {
      original: 'img/Galary3.jpeg',
      thumbnail: 'img/Galaryth3.png',
    },
    {
      original: 'img/Galary4.jpeg',
      thumbnail: 'img/Galaryth4.png',
    },
  ];
export default class GalleryHome extends Component {
    
    render() {
       
        return (
            <Container  fluid style={{padding:'40px'}}>
                <Row>
                    <Col>
                        <center><div>GALLERY</div></center>
                        <div style={{width:'100%'}}>
                            <ImageGallery items={images} />
                        </div>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}
